import { React, useState } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

function Navbar() {
  const [open, setOpen] = useState(false);

  return (
    <div className="flex justify-between items-center  text-white  mx-w-2xl  max-w-[1240px] mx-auto px-1">
      <h1 className="text-3xl font-bold text-[#00df98] ">REACT.</h1>

      <ul className="hidden md:flex ">
        <li className="p-3  cursor-pointer">Home</li>
        <li className="p-3  cursor-pointer">Company</li>
        <li className="p-3  cursor-pointer">Resources</li>
        <li className="p-3  cursor-pointer">About</li>
        <li className="p-3  cursor-pointer">Contact</li>
      </ul>

      <div className="md:hidden" onClick={() => setOpen(!open)}>
        {open ? (
          <AiOutlineClose className=" cursor-pointer " size={20} />
        ) : (
          <AiOutlineMenu className=" cursor-pointer " size={20} />
        )}
      </div>
      {open && (
        <ul className="md:hidden fixed top-0 left-0 pt-20 bg-black-600 h-full w-[60%]  ease-in-out duration-700">
          <li className="p-3 border-b border-b-gray-800  cursor-pointer">
            Home
          </li>
          <li className="p-3  cursor-pointer border-b border-b-gray-800">
            Company
          </li>
          <li className="p-3  cursor-pointer border-b border-b-gray-800">
            Resources
          </li>
          <li className="p-3 cursor-pointer border-b border-b-gray-800">
            About
          </li>
          <li className="p-3 cursor-pointer">Contact</li>
        </ul>
      )}
    </div>
  );
}

export default Navbar;
