import React from "react";

function Hero() {
  return (
    <div className="text-white">
      <div className=" flex flex-col  align-center mx-auto text-center mt-[100px] h-screen pt-16 ">
        <p className="text-[#00df98] text-sm md:text-md">
          GROWING WITH DATA ANALYTICS
        </p>
        <h1 className="text-5xl md:text-6xl  font-bold">Grow with data.</h1>
        <p className="p-1">Fast and flexible financing</p>
        <p className="text-gray-500 mt-3">
          Monitor your data analytics to increase revenue for BTB, BTC and SASS
          platforms
        </p>
        <button className=" mx-auto p-2 px-4 md:px-10  mt-3 bg-[#00df98] text-black font-medium rounded-sm">
          Get Started
        </button>
      </div>
    </div>
  );
}

export default Hero;
